<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">考试管理</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <a class="crumbs_item" @click="$router.go(-1)">考试报名 / </a>
          <span class="crumbs_item crumbs_last">新建报名</span>
        </div>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="content_box">
      <a-row class="a-row-item">
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">考试日期：</span></a-col>
        <a-col :span="6">
          <a-date-picker class="right" 
            format="YYYY-MM-DD"
            v-model="form.examStartDate" 
            :disabledDate="disabledDate"
            placeholder="请选择考试日期" />
        </a-col>
      </a-row>
      <a-row class="a-row-item" >
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">考试场次：</span></a-col>
        <a-col :span="6" style="min-width:600px">
          <span class="number" @click="isSessionsShow = true,type = 1">新建场次</span>
          <div class="table-box">
            <a-table
              class="table-template"
              :rowKey="(record,index)=>{return index}"
              :columns="columns"
              :data-source="timeJson"
              :pagination="false"
              >
                <template slot="index" slot-scope="item, row, i">
                  <div style="text-align: center;">
                    {{ i + 1 }}
                  </div>
                </template>
            
                <template slot="time" slot-scope="item">
                  <span>{{item.examStartDate}} - {{item.examEndDate}}</span>
                </template>

                <template slot="operation" slot-scope="item, row, i">
                  <div class="btn_router_link">
                    <a @click="onEdit(item,i)">编辑</a>
                    <span>|</span>
                    <router-link to >
                      <a-popconfirm title="确认删除吗？" ok-text="确认" @confirm="onDel(i)" cancel-text="取消">
                        <a href="#">删除</a>
                      </a-popconfirm>
                    </router-link>
                  </div>
                </template>
              </a-table>
          </div>
        </a-col>
      </a-row>
      <a-row class="a-row-item"  v-if="false">
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">考试形式：</span></a-col>
        <a-col :span="6">
          <a-radio-group v-model="form.examStatus" @change="onRadio">
            <a-radio :style="radioStyle" :value="1">线上考试</a-radio>
            <a-radio :style="radioStyle" :value="0">
              <span>线下考试</span>
              <span style="margin-left:20px">请填写地址：
                <Province @regionCity='FunProvince' :value="addressValue"></Province>
                <a-input style="margin-left:10px" v-model="form.address" placeholder="请输入详细地址" />
              </span>
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="a-row-item">
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">报名时间：</span></a-col>
        <a-col :span="6">
          <a-range-picker class="right"
            v-model="signUpTime"
            :disabledDate="disabledDate"
            format="YYYY-MM-DD HH:00:00"
            :showTime="{ initialValue: signUpTime.length ? moment('00:00:00', 'HH:00:00') : null }"
            :placeholder="['报名开始时间', '报名结束时间']" />
        </a-col>
      </a-row>
      <a-row class="a-row-item">
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">审核时间：</span></a-col>
        <a-col :span="6">
          <a-date-picker class="right"
            v-model="form.auditTime"
            format="YYYY-MM-DD HH:00:00"
            :disabledDate="disabledDate"
            :showTime="{ initialValue: form.auditTime ? moment('00:00:00', 'HH:00:00') : null }"
            placeholder="请选择审核时间" />
          <p class="tips">提示：到审核时间时，系统将自动审核报名信息</p>
        </a-col>
      </a-row>
      <a-row class="a-row-item" v-show="false">
        <a-col class="left_inner" :span="2"><span class="all_left_name all_required">自动驳回原因：</span></a-col>
        <a-col :span="6">
          <a-textarea
            v-model="form.autoRejectReason"
            placeholder="请输入自动驳回学员报名信息原因"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
          <p class="tips">提示：当学员信息为自动审核被驳回时，该内容将会以短信形式通知用户</p>
        </a-col>
      </a-row>
    </div>
    <div class="foot_btn">
      <a-button type="primary" class="btn" :loading="loading" @click="onFormSave">确认</a-button>
      <a-button  class="all_boder_btn" @click="$router.go(-1)">取消</a-button>
    </div>
    <!-- 新建场次 -->
    <a-modal v-model="isSessionsShow" align="center"  title="新建场次" >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name all_required">考试时间：</span>
          <div>
            <a-time-picker
              style="width:48%"
              format="HH:mm:ss"
              v-model="sessionForm.examStartDate"
              placeholder="考试开始时间"
            /> - 
            <a-time-picker
              style="width:48%"
              format="HH:mm:ss"
              v-model="sessionForm.examEndDate"
              placeholder="考试结束时间"
               />
          </div>
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">考试名额：</span>
          <a-input-number class="right" id="inputNumber" v-model="sessionForm.signupNum" :min="1" :max="99999"  placeholder="请输入考试名额" />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="addSessions" :loading="loading">确认</a-button>
        <a-button  @click="onCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "考试时间",
    align:'center',
    width: '200px',
    scopedSlots: { customRender: 'time' }

  },
  {
    title: "考试名额",
    align:'center',
    dataIndex: "signupNum"
  },
  {
    title: "操作",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];
import Province from '@/components/provinceCity'
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: {Province},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading:false,
      columns,
      radioStyle: {  // 单项 垂直分布
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      CascaderList:[], // 省市区列表
      isSessionsShow: false,
      type:0,  // 场次添加状态 1.添加 2.编辑
      timeJson:[],
      timeIndex: 0, // 场次索引
      sessionForm:{ // 场次绑定
        examStartDate:null,  // 开始时间
        examEndDate:null, // 结束时间
        signupNum:'',  // 最大报名人数
      },
      DeletedArry:[],  // 存放已经删除的场次

      signUpTime: [],  // 报名时间
      form:{
        signupId:'',
        configId:'',
        examStartDate: null, // 考试日期
        timeJson: [], // 考试场次
        examStatus: 1, // 考试形式  1.线上 0.线下
        province: '',  // 省
        city: '',  // 市
        area: '',  // 区
        address: '',  // 详细地址
        signupStartDate: null, // 报名开始时间
        signupEndDate: null, // 报名结束时间
        auditTime	: null, // 审核时间
        autoRejectReason: '', // 自动驳回原因
      },
      offsetDays:'2022-03-25',
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          isLeaf: false,
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          isLeaf: false,
        },

      ],
      addressValue:[],   // 省市区
    }
  },
  // 事件处理器
  methods: {
    moment,
    // 获取省市区编码
    FunProvince (data) {
      this.addressValue = data
    },
    // 获取详情
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/signup/get",
        params:{
          signupId:this.form.signupId
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.form = res.data
          this.timeJson = res.data.timeList
          if(this.form.examStatus==0){// 0 代表线下 形式
            this.addressValue = [this.form.province, this.form.city]  // 省市区回显
            if(this.form.area){  // 判断部分地区没有区
              this.addressValue.push(this.form.area)
            }
          }  
          this.signUpTime = [res.data.signupStartDate,res.data.signupEndDate] // 报名时间段 赋值
        }
      })
    },
    onCascaderChange(){},
    // 新建/编辑 报名
    onFormSave(){
      // 找出考试场次中最早的一场考试，跟考试日期拼接起来
      let timeJson = [...this.timeJson, ...this.DeletedArry]  // 将场次列表与逻辑删除的场次 进行合并
      this.form.timeJson = JSON.stringify(timeJson)  // 考试场次转json
      let minTimeArry=[]
      timeJson.forEach(element => {
        minTimeArry.push(element.examStartDate)
      });
      let minTime = this.moment(this.form.examStartDate).format("YYYY-MM-DD") + ' ' +  minTimeArry.sort()[0]  // 考试当天最早的日期

      if(!this.form.examStartDate){
        this.$message.warning('请选择考试日期');
        return
      }
      if(!this.timeJson.length){
        this.$message.warning('请至少创建一场考试场次');
        return
      }
      if(!this.form.examStatus && !this.addressValue.length){
        this.$message.warning('请选择省市区');
        return
      }
      if(!this.form.examStatus && !this.form.address){
        this.$message.warning('请输入详细地址');
        return
      }
      if(!this.signUpTime.length){
        this.$message.warning('请选择报名时间段');
        return
      }
      if(this.moment(this.signUpTime[1]).format("YYYY-MM-DD HH:mm:ss") > this.moment(minTime).format("YYYY-MM-DD HH:mm:ss")){
        this.$message.warning('报名结束日期不能晚于或等于考试开始时间');
        return
      }
      if(!this.form.auditTime){
        this.$message.warning('请选择审核日期');
        return
      }
      if(this.moment(this.form.auditTime).format("YYYY-MM-DD HH:mm:ss") > this.moment(minTime).format("YYYY-MM-DD HH:mm:ss")){
        this.$message.warning('审核日期不能晚于或等于考试开始时间');
        return
      }
      
      // 数据转换
      this.form.examStartDate = this.moment(this.form.examStartDate).format("YYYY-MM-DD HH:mm:ss") // 开始日期
      this.form.signupStartDate = this.moment(this.signUpTime[0]).format("YYYY-MM-DD HH:00:00") // 报名开始时间
      this.form.signupEndDate = this.moment(this.signUpTime[1]).format("YYYY-MM-DD HH:00:00") // 报名结束时间
      this.form.auditTime = this.moment(this.form.auditTime).format("YYYY-MM-DD HH:00:00")  // 审核日期

      // 省市区赋值
      this.form.province = this.addressValue[0]
      this.form.city = this.addressValue[1]
      this.form.area = this.addressValue.length > 2 ? this.addressValue[2] : ''


      // let timeJson = [...this.timeJson, ...this.DeletedArry]  // 将场次列表与逻辑删除的场次 进行合并
      // this.form.timeJson = JSON.stringify(timeJson)  // 考试场次转json
      this.loading = true
      this.$ajax({
        method: this.form.signupId ? 'put' : 'post',
        url: this.form.signupId ? '/hxclass-management/exam/signup/edit' : '/hxclass-management/exam/signup/save',
        params: this.form
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$router.go(-1)
          this.$message.success(res.message);
        } else{
          this.$message.warning(res.message);
        }
      })
    },
    // 添加场次
    addSessions(){
      if(!this.form.examStartDate){
        this.$message.warning('请先选择考试日期');
        return
      }
      if(!this.sessionForm.examStartDate || !this.sessionForm.examEndDate){
        this.$message.warning('请添加考试场次时间');
        return
      }
      if(this.moment(this.sessionForm.examStartDate).format("HH:mm:ss") == this.moment(this.sessionForm.examEndDate).format("HH:mm:ss")){
        this.$message.warning('考试开始时间与结束时间不能相等');
        return
      }
      if(this.moment(this.sessionForm.examStartDate).format("HH:mm:ss") > this.moment(this.sessionForm.examEndDate).format("HH:mm:ss")){
        this.$message.warning('考试结束时间不能小于开始时间');
        return
      }
      if(!this.sessionForm.signupNum){
        this.$message.warning('请输入考试名额');
        return
      }
      // 数据转换
      this.sessionForm.examStartDate = this.moment(this.sessionForm.examStartDate).format("HH:mm:ss")
      this.sessionForm.examEndDate = this.moment(this.sessionForm.examEndDate).format("HH:mm:ss")

      if(this.type == 1){ // 新增
        this.timeJson = [...this.timeJson,this.sessionForm]
      } else {  // 编辑
        this.timeJson[this.timeIndex].examStartDate = this.sessionForm.examStartDate
        this.timeJson[this.timeIndex].examEndDate = this.sessionForm.examEndDate
        this.timeJson[this.timeIndex].signupNum = this.sessionForm.signupNum
      }
      this.isSessionsShow=false

      // 置空对象
      this.sessionForm = {
        examStartDate:'', 
        examEndDate:'',  
        signupNum:'', 
      }
    },
    onCancel(){
      this.isSessionsShow=false
      // 置空对象
      this.sessionForm = {
        examStartDate:'', 
        examEndDate:'',  
        signupNum:'', 
      }
    },
    // 编辑场次
    onEdit(e,i){
      this.timeIndex = i
      this.sessionForm.examStartDate = moment(e.examStartDate, 'HH:mm:ss') 
      this.sessionForm.examEndDate = moment(e.examEndDate, 'HH:mm:ss') 
      this.sessionForm.signupNum = e.signupNum
      this.type = 2
      this.isSessionsShow = true
    },
    // 删除场次
    onDel(i){
      if(this.timeJson[i].timeId){  // 如果场次有id 做逻辑删除，暂存到已删除场次数组
        this.timeJson[i].isDelete = 1
        this.DeletedArry.push(this.timeJson[i])
      }
      this.timeJson.splice(i,1)
    },
    // 禁止选择今天之前的日期
    disabledDate (current) {
      return current && current < moment().subtract(1, 'days').endOf('day')
    },

    onRadio(e){
      this.form.examStatus = e.target.value
      if(e.target.value){
        this.addressValue = []
        this.form.address = ''
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.form.configId = this.$route.query.configId;  // 获取考试id
    this.form.signupId = this.$route.query.id;  // 获取报名id
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    if(this.form.signupId){
      this.getFormDetai()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content_box{
  margin: 20px 0;
}
.a-row-item{
  margin-bottom: 20px;
  .left_inner{
    text-align: right;
  }
  .number{
    font-size: 12px;
    line-height: 32px;
    color: #2B6ED4;
    cursor: pointer;
  }
  .right{
    width: 100%;
  }
  .tips{
    margin-top: 5px;
    font-size: 12px;
    color: #FF3838;
  }
}
.all_left_name{
  line-height: 32px;
}
.foot_btn{
  margin-left: 135px;
  .btn{
    margin-right: 20px;
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
    >div{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .line-item:last-child{
    margin-bottom: 0;
  }
  .right{
    flex: 1;
    /deep/.ant-input{
      height: 40px;
    }
  }
}
</style>
